import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import LayoutCustom from "../../components/LayoutCustom";
import Footer from "../../components/Footer";
import SEO from "../../components/SEO";

const AboutUs = ({ data }) => {

    const aboutus_introduction = data.aboutus_introduction.nodes;
    const aboutus_introduction_vi = data.aboutus_introduction_vi.nodes;
    const aboutus_history = data.aboutus_history.nodes;
    const aboutus_history_vi = data.aboutus_history_vi.nodes;
    const { html } = data.markdownRemark;
    const { title, subTitle } = data.markdownRemark.frontmatter;
    const title_vi = data.markdownRemark_vi.frontmatter.title;
    const subTitle_vi = data.markdownRemark_vi.frontmatter.subTitle;

    return (
        <LayoutCustom>
            <SEO title="About Us" description="Appvity consists of a team of professionals that aspires to build productivity software that enables information professionals to work effectively. We build products that focus on Azure and Office 365 cloud platform." meta={[{ name: `keywords`, content: ["collaboration", "task management"] }]} pathname="/about-us" />
            <div className="main-content-padding">
                <div className="app-aboutus-introduction" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                    <Container>
                        <div className="app-section-aboutus-detail">
                            <Row>
                                <Col xs={12} md={12} lg={5} className="app-aboutus-detail-col-custom">
                                    {aboutus_introduction.map((res) => (
                                        <div className="app-data-en app-aboutus-detail-left" key={res.id} dangerouslySetInnerHTML={{ __html: res.html }} />
                                    ))}
                                    {aboutus_introduction_vi.map((res) => (
                                        <div className="app-data-vi app-aboutus-detail-left" key={res.id} dangerouslySetInnerHTML={{ __html: res.html }} />
                                    ))}
                                </Col>
                                <Col xs={12} md={12} lg={1}></Col>
                                <Col xs={12} md={12} lg={6}>
                                    <div className="app-aboutus-detail-right">
                                        <img src="/app-aboutus-introduction.png" alt="app aboutus introduction" />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                <div className="app-our-history" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                    <Container>
                        <div className="app-section-our-history-detail">
                            <Row>
                                <Col xs={12} md={12} lg={5}>
                                    <div className="app-our-history-detail-left">
                                        <h5 className="app-data-en app-aboutus-subTitle">{subTitle}</h5>
                                        <h5 className="app-data-vi app-aboutus-subTitle">{subTitle_vi}</h5>
                                        <div className="app-title">
                                            <h2 className="app-data-en app-aboutus-title">{title}</h2>
                                            <h2 className="app-data-vi app-aboutus-title">{title_vi}</h2>
                                        </div>
                                        {/* <div dangerouslySetInnerHTML={{ __html: html }} /> */}
                                        {aboutus_history.map((res) => (
                                            <div className="app-data-en app-aboutus-history" key={res.id} dangerouslySetInnerHTML={{ __html: res.html }} />
                                        ))}
                                        {aboutus_history_vi.map((res) => (
                                            <div className="app-data-vi app-aboutus-history" key={res.id} dangerouslySetInnerHTML={{ __html: res.html }} />
                                        ))}
                                    </div>
                                </Col>
                                <Col xs={12} md={12} lg={7}></Col>
                            </Row>
                        </div>
                    </Container>
                    <div className="footer-custom">
                        <Footer />
                    </div>
                </div>
            </div>
        </LayoutCustom>
    )
}

export default AboutUs;

export const query = graphql`
    query AboutUsIntroductionPage {
        aboutus_introduction: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "aboutus_introduction" } } }
        ) {
            nodes {
                html
                id
            }
        }
        aboutus_introduction_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "aboutus_introduction_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        aboutus_history: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "aboutus_history" } } }
        ) {
            nodes {
                html
                id
            }
        }
        aboutus_history_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "aboutus_history_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        markdownRemark: markdownRemark(frontmatter: {directory: {eq: "aboutus_history"}}) {
            html
            frontmatter {
                title
                subTitle
            }
            id
        }
        markdownRemark_vi: markdownRemark(frontmatter: {directory: {eq: "aboutus_history_vi"}}) {
            html
            frontmatter {
                title
                subTitle
            }
            id
        }
    }
`;
